<template>
  <div class="candlestick">
    <div class="citys tabs">
      <div
        v-for="item in citys"
        :key="item.value"
        :class="['city', { active: active == item.value }]"
        @click="handleSelectCity(item.value)"
      >
        <span v-show="activeSwitch === 'k'">{{ item.name }}</span>
        <!-- <span >{{ item.name }}</span> -->
      </div>
    </div>
    <!-- 单项数据 -->
    <div v-if="false" class="form">
      <div class="top">
        <div class="open">
          <span class="label">开盘价：</span>
          <span class="value">{{ form.open || "-" }}</span>
        </div>
        <div class="close">
          <span class="label">收盘价：</span>
          <span class="value">{{ form.close || "-" }}</span>
        </div>
        <div class="low">
          <span class="label">最低价：</span>
          <span class="value">{{ form.low || "-" }}</span>
        </div>
        <div class="high">
          <span class="label">最高价：</span>
          <span class="value">{{ form.high || "-" }}</span>
        </div>
      </div>
      <div class="bottom">
        <div class="amount">
          <span class="label">成交量：</span>
          <span class="value">{{ form.open || "-" }}</span>
        </div>
        <div class="total">
          <span class="label">成交额：</span>
          <span class="value">{{ form.total || "-" }}</span>
        </div>
      </div>
    </div>
    <!-- k线 走势 -->
    <!-- <div class="switchs tabs">
      <span
        :class="['k', 'switch', { active: activeSwitch == 'k' }]"
        @click="handleSwitch('k')"
        >k线</span
      >
      <span
        :class="['trend', 'switch', { active: activeSwitch == 'trend' }]"
        @click="handleSwitch('trend')"
        >走势</span
      >
    </div> -->

    <div class="echart-wrapper">
      <div class="echart-ref" ref="echart_ref"></div>
      <div class="echart-ref" ref="echart_ref_combine"></div>
    </div>
  </div>
</template>
<script>
import * as echarts from "echarts";
import { toFixed } from "../../../utils/util";
export default {
  name: "Candlestick",
  data() {
    return {
      newlist: [],
      newdate_list: [],
      newprice_list: [],
      newamount_list: [],
      newtotal_list: [],
      tableShow: true,
      tableData: [],
      citys: [
        { name: "全国", value: "china" },
        { name: "湖北", value: "hubei" },
        { name: "深圳", value: "shenzhen" },
        { name: "广东", value: "guangdong" },
        { name: "天津", value: "tianjin" },
        { name: "上海", value: "shanghai" },
        { name: "北京", value: "beijing" },
        { name: "重庆", value: "chongqin" },
        { name: "福建", value: "fujian" },
      ],
      active: "hubei",
      activeSwitch: "",
      ref: null,
      instance: null,
      combine_ref: null,
      combine_instance: null,
      options: null,
      combine_options: null,
      date_list: [],
      price_list: [],
      amount_list: [],
      total_list: [],
      resizing: null,
      form: {
        time: "今天",
        open: "",
        close: "",
        low: "",
        high: "",
        amount: "",
        total: "",
      },
      current_dates: [],
      china_prices: [],
      hubei_prices: [],
      shenzhen_prices: [],
      guangdong_prices: [],
      tianjin_prices: [],
      shanghai_prices: [],
      beijing_prices: [],
      chongqin_prices: [],
      fujian_prices: [],
    };
  },
  mounted() {
    // console.log("Candlestick, activated");
    if (this.$route.query.all) {
      this.active = "china";
    } else {
      this.active = "hubei";
    }
   
    window.addEventListener("resize", this.handleResize);
  },
  activated() { this.handleSwitch("k");},
  deactivated() {
    window.removeEventListener("resize", this.handleResize);
    this.activeSwitch = "";
  },
  methods: {
    edit(val) {
      // console.log(val, "val");
      // console.log(this.$refs.tableref);
    },
    startUsing() {},
    rowClick(val) {
      console.log("val", val);
    },
    initInstance() {
      let ref = this.$refs.echart_ref;
      this.ref = ref instanceof Array ? ref[0] : ref;
      this.instance = echarts.init(this.ref, null, { renderer: "svg" });
      let that = this;
      this.instance.on("showTip", function (params) {});
      this.instance.on("hideTip", function (params) {});
    },
    initKOption() {
      let upColor = "#ec0000";
      let downColor = "#00da3c";
      let that = this;
      this.options = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              show: false,
            },
            lineStyle: {
              type: "solid",
            },
            crossStyle: {
              type: "solid",
            },
          },
          formatter: (params) => {
            let open = "-";
            let close = "-";
            let low = "-";
            let high = "-";
            // 成交量
            let amount = "-";
            let total = "-";
            let time = that.date_list[params[0].dataIndex];
            open = that.price_list[params[0].dataIndex][0];
            close = that.price_list[params[0].dataIndex][1];
            low = that.price_list[params[0].dataIndex][2];
            high = that.price_list[params[0].dataIndex][3];
            amount = that.amount_list[params[0].dataIndex];
            total = that.total_list[params[0].dataIndex];
            open =
              open == "" || open == undefined || /'-'/.test(open)
                ? "-"
                : toFixed(open, 2);
            close =
              close == "" || close == undefined || /'-'/.test(close)
                ? "-"
                : toFixed(close, 2);
            low =
              low == "" || low == undefined || /'-'/.test(low)
                ? "-"
                : toFixed(low, 2);
            high =
              high == "" || high == undefined || /'-'/.test(high)
                ? "-"
                : toFixed(high, 2);
            amount =
              amount == "" || amount == undefined || /'-'/.test(amount)
                ? "-"
                : amount;
            total =
              total == "" || total == undefined || /'-'/.test(total)
                ? "-"
                : toFixed(total, 2);
            return (
              time +
              "<br>" +
              "开盘价: " +
              open +
              "<br>" +
              "收盘价: " +
              close +
              "<br>" +
              "最低价: " +
              low +
              "<br>" +
              "最高价: " +
              high +
              "<br>" +
              "成交量: " +
              amount +
              "<br>" +
              "成交额: " +
              total +
              "<br>"
            );
          },
        },
        axisPointer: {
          link: [
            {
              xAxisIndex: "all",
            },
          ],
          label: {
            backgroundColor: "#777",
          },
        },
        toolbox: {
          feature: {
            dataZoom: {
              show: false,
              yAxisIndex: false,
            },
            brush: {
              type: ["lineX", "clear"],
              show: false,
            },
          },
        },
        brush: {
          xAxisIndex: "all",
          brushLink: "all",
          outOfBrush: {
            colorAlpha: 0.1,
          },
        },
        legend: {
          data: [],
          show: false,
        },
        grid: [
          {
            top: "5%",
            left: 55,
            right: 35,
            height: "52%",
          },
          {
            top: "70%",
            left: 55,
            right: 35,
            bottom: "12%",
          },
        ],
        xAxis: [
          {
            type: "category",
            data: [],
            boundaryGap: false,
            axisLine: { onZero: false },
            splitLine: { show: false },
          },
          {
            type: "category",
            data: [],
            gridIndex: 1,
            boundaryGap: false,
            axisLine: { onZero: false },
            splitLine: { show: false },
            axisLabel: { show: false },
          },
        ],
        yAxis: [
          {
            scale: true,
            splitArea: {
              show: false,
            },
            min: function (value) {
              let temp = value.min;
              return temp > 0.5 ? temp - 0.5 : 0;
            },
            max: function (value) {
              let temp = value.max;
              return temp + 0.5;
            },
          },
          {
            scale: true,
            gridIndex: 1,
            axisLabel: { show: true },
            axisLine: { show: false },
            axisTick: { show: false },
            splitLine: { show: false },
          },
        ],
        dataZoom: [
          {
            type: "inside",
            xAxisIndex: [0, 1],
            start: 95,
            end: 100,
            startValue: 0,
            endValue: 100,
          },
          {
            start: 95,
            end: 100,
            show: false,
            xAxisIndex: [0, 1],
            type: "slider",
            top: "90%",
            startValue: 0,
            endValue: 100,
          },
        ],
        series: [
          {
            name: "日K",
            type: "candlestick",
            data: [],
            itemStyle: {
              color: upColor,
              color0: downColor,
              borderColor: undefined,
              borderColor0: undefined,
            },
          },
          {
            name: "成交量",
            type: "bar",
            data: [],
            xAxisIndex: 1,
            yAxisIndex: 1,
            itemStyle: {
                color: function (params) {
                  let data = that.options.series[0].data[params.dataIndex];
                  let open = data[0];
                  let close = data[1];
                  if (!open || !close || /-/.test(open) || /-/.test(close)) {
                    return "gray";
                  } else if (open > close) {
                    return downColor;
                  } else if (open < close) {
                    return upColor;
                  } else if (open == close) {
                    return upColor;
                  } else {
                    return "gray";
                  }
                },
             
            },
          },
        ],
      };
    },
    initTrendOption() {
      this.options = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              show: false,
            },
            lineStyle: {
              type: "solid",
              // color: '#fff',
            },
            crossStyle: {
              type: "solid",
              // color: '#fff',
            },
          },
          // position: function (pos, params, el, elRect, size) {
          //     const obj = {
          //         top: '10%'
          //     };
          //     obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 30;
          //     return obj;
          // },
          // valueFormatter: (value) => {
          //     return ' 收盘价:' + (typeof value === 'number' && !isNaN(value) ? toFixed(value, 2) : '-')
          // }
          formatter: (params) => {
            //修改鼠标划过显示为中文
            // console.log('tooltip', params);
            let Marker =
              '<span style="display:inline-block;margin-right:6px;border-radius:10px;width:10px;height:10px;background-color:#color;"></span>';
            let str = "";
            for (let i = 0; i < params.length; i++) {
              let sub_str = "";
              let color = params[i].color;
              let seriesName = params[i].seriesName;
              let marker = Marker.replace("#color", color);
              let value = params[i].data;
              if (typeof value === "number" && !isNaN(value)) {
                value = toFixed(value, 2) + "元";
              } else {
                value = "-";
              }
              if (!i) {
                sub_str += params[i].name + "<br>";
              }
              sub_str += marker;
              sub_str += seriesName;
              sub_str += " 收盘价: " + value;
              if (i !== params.length - 1) {
                sub_str += "<br>";
              }
              str += sub_str;
            }
            // console.log('tooltip', params);
            return str;
          },
        },
        // toolbox: {
        //     feature: {
        //         dataZoom: {
        //             // show: false,
        //             // yAxisIndex: false
        //         },
        //         brush: {
        //             type: ['lineX', 'clear']
        //         }
        //     }
        // },
        // brush: {
        //     xAxisIndex: 'all',
        //     brushLink: 'all',
        //     outOfBrush: {
        //         colorAlpha: 0.1
        //     }
        // },
        legend: {
          data: this.citys.map((item) => item.name),
          // show: false,
        },
        grid: [
          {
            left: 80,
            right: 35,
            bottom: 40,
            top: "10%",
            height: "65%",
          },
        ],
        xAxis: [
          {
            type: "category",
            data: [],
            boundaryGap: false,
            axisLine: { onZero: false },
            splitLine: { show: false },
            // inverse: true,
            // axisLabel: { show: false },
          },
        ],
        yAxis: [
          {
            axisLabel: { show: true },
            axisLine: { show: false },
            axisTick: { show: false },
            splitLine: { show: true },
            splitArea: {
              show: false,
            },
          },
        ],
        dataZoom: [
          {
            type: "inside",
            // xAxisIndex: [0, 1],
            startValue: 999790,
            endValue: 999999,
            // minValueSpan: 50,
            // maxValueSpan: 50,
          },
          {
            type: "slider",
            startValue: 999790,
            endValue: 999999,
            top: "80%",
            // minValueSpan: 50,
            // maxValueSpan: 50,
          },
        ],
        series: [
          {
            name: "全国",
            type: "line",
            data: [],
            smooth: true,
            symbol: "circle",
            symbolSize: 10,
            showSymbol: false,
            connectNulls: false,
            itemStyle: {
              color: "red",
              shadowColor: "red",
              shadowBlur: 15,
              // '#5470c6',
              // shadowOffsetX: 1,
              // shadowOffsetY: 1
            },
          },
          {
            name: "湖北",
            type: "line",
            data: [],
            smooth: true,
            symbol: "circle",
            symbolSize: 10,
            showSymbol: false,
            connectNulls: false,
            itemStyle: {
              color: "#91cc75",
              shadowColor: "#91cc75",
              shadowBlur: 15,
            },
          },
          {
            name: "深圳",
            type: "line",
            data: [],
            smooth: true,
            symbol: "circle",
            symbolSize: 10,
            showSymbol: false,
            connectNulls: false,
            itemStyle: {
              color: "#fac858",
              shadowColor: "#fac858",
              shadowBlur: 15,
              // color: upColor,
              // color0: downColor,
              // borderColor: undefined,
              // borderColor0: undefined,
            },
          },
          {
            name: "广东",
            type: "line",
            data: [],
            smooth: true,
            symbol: "circle",
            symbolSize: 10,
            showSymbol: false,
            connectNulls: false,
            itemStyle: {
              color: "#ee6666",
              shadowColor: "#ee6666",
              shadowBlur: 15,
              // color: upColor,
              // color0: downColor,
              // borderColor: undefined,
              // borderColor0: undefined,
            },
          },
          {
            name: "天津",
            type: "line",
            data: [],
            smooth: true,
            symbol: "circle",
            symbolSize: 10,
            showSymbol: false,
            connectNulls: false,
            itemStyle: {
              color: "#73c0de",
              shadowColor: "#73c0de",
              shadowBlur: 15,
              // color: upColor,
              // color0: downColor,
              // borderColor: undefined,
              // borderColor0: undefined,
            },
          },
          {
            name: "上海",
            type: "line",
            data: [],
            smooth: true,
            symbol: "circle",
            symbolSize: 10,
            showSymbol: false,
            connectNulls: false,
            itemStyle: {
              color: "#3ba272",
              shadowColor: "#3ba272",
              shadowBlur: 15,
              // color: upColor,
              // color0: downColor,
              // borderColor: undefined,
              // borderColor0: undefined,
            },
          },
          {
            name: "北京",
            type: "line",
            data: [],
            smooth: true,
            symbol: "circle",
            symbolSize: 10,
            showSymbol: false,
            connectNulls: false,
            itemStyle: {
              color: "#fc8452",
              shadowColor: "#fc8452",
              shadowBlur: 15,
              // color: upColor,
              // color0: downColor,
              // borderColor: undefined,
              // borderColor0: undefined,
            },
          },
          {
            name: "重庆",
            type: "line",
            data: [],
            smooth: true,
            symbol: "circle",
            symbolSize: 10,
            showSymbol: false,
            connectNulls: false,
            itemStyle: {
              color: "#9a60b4",
              shadowColor: "#9a60b4",
              shadowBlur: 15,
              // color: upColor,
              // color0: downColor,
              // borderColor: undefined,
              // borderColor0: undefined,
            },
          },
          {
            name: "福建",
            type: "line",
            data: [],
            smooth: true,
            symbol: "circle",
            symbolSize: 10,
            showSymbol: false,
            connectNulls: false,
            itemStyle: {
              color: "#ea7ccc",
              shadowColor: "#ea7ccc",
              shadowBlur: 15,
              // color: upColor,
              // color0: downColor,
              // borderColor: undefined,
              // borderColor0: undefined,
            },
          },
        ],
      };
    },
    getKEchartsData() {
      // http://192.168.23.15:8000/prices/getcarbonbusinesslist
      this.instance.showLoading({ text: "" });
      // this.combine_instance.showLoading();
      this.api
        .postFormAPISM(
          {
            city: this.active,
            limit: 99999,
            send_time: "",
          },
          "/prices/getcarbonbusinesslist"
        )
        .then((res) => {
          // console.log('getEchartsData', res.data);
          this.newprice_list = res.data.data.price_list.slice(0, 30);
          this.newdate_list = res.data.data.date_list.slice(0, 30);
          this.newamount_list = res.data.data.amount_list.slice(0, 30);
          this.newtotal_list = res.data.data.total_list.slice(0, 30);
          if (this.newlist.length == 0) {
            for (let i = 0; i < 30; i++) {
              let tmpe = {
                newprice_list: { ...this.newprice_list[i] },
                newamount_list: this.newamount_list[i],
                newtotal_list: this.newtotal_list[i],
                newdate_list: this.newdate_list[i],
              };
              this.newlist.push(tmpe);
            }
            console.log(this.newlist, "this.newlist");
          } else {
            this.newlist.splice(0, 30);
            for (let i = 0; i < 30; i++) {
              let tmpe = {
                newprice_list: { ...this.newprice_list[i] },
                newamount_list: this.newamount_list[i],
                newtotal_list: this.newtotal_list[i],
                newdate_list: this.newdate_list[i],
              };
              this.newlist.push(tmpe);
            }
          }
          // console.log(this.newlist,'this.newlist');
          this.price_list = res.data.data.price_list.reverse();
          this.date_list = res.data.data.date_list.reverse();
          this.amount_list = res.data.data.amount_list.reverse();
          this.total_list = res.data.data.total_list.reverse();

          this.setKOption();
        })
        .catch((error) => {
          console.log("getEchartsData, error", error);
        });
    },
    getTrendEchartsData() {
      // http://192.168.23.15:8000/prices/getcarbonpricetrend
      this.instance.showLoading({ text: "" });
      // this.combine_instance.showLoading();
      this.api
        .postFormAPISM(
          {
            limit: 999999,
            send_time: "",
          },
          "/prices/getcarbonpricetrend"
        )
        .then((res) => {
          console.log("getTrendEchartsData", res.data);
          this.china_prices = res.data.data.china_prices.slice(0, 30);
          this.hubei_prices = res.data.data.hubei_prices.slice(0, 30);
          this.shenzhen_prices = res.data.data.shenzhen_prices.slice(0, 30);
          this.guangdong_prices = res.data.data.guangdong_prices.slice(0, 30);
          this.tianjin_prices = res.data.data.tianjin_prices.slice(0, 30);
          this.shanghai_prices = res.data.data.shanghai_prices.slice(0, 30);
          this.chongqin_prices = res.data.data.chongqin_prices.slice(0, 30);
          this.current_dates = res.data.data.current_dates.slice(0, 30);
          this.beijing_prices = res.data.data.beijing_prices.slice(0, 30);

          if (this.tableData.length == 0) {
            for (let i = 0; i < 30; i++) {
              let tmpe = {
                china_prices: this.china_prices[i],
                hubei_prices: this.hubei_prices[i],
                shenzhen_prices: this.shenzhen_prices[i],
                guangdong_prices: this.guangdong_prices[i],
                tianjin_prices: this.tianjin_prices[i],
                shanghai_prices: this.shanghai_prices[i],
                chongqin_prices: this.chongqin_prices[i],
                current_dates: this.current_dates[i],
                beijing_prices: this.beijing_prices[i],
              };
              this.tableData.push(tmpe);
            }
            this.tableData.forEach((e) => {
              // console.log(e,'e');
              if (e.china_prices == "None") {
                e.china_prices = "-";
              }
              if (e.hubei_prices == "None") {
                e.hubei_prices = "-";
              }
              if (e.shenzhen_prices == "None") {
                e.shenzhen_prices = "-";
              }
              if (e.guangdong_prices == "None") {
                e.guangdong_prices = "-";
              }
              if (e.tianjin_prices == "None") {
                e.tianjin_prices = "-";
              }
              if (e.shanghai_prices == "None") {
                e.shanghai_prices = "-";
              }
              if (e.chongqin_prices == "None") {
                e.chongqin_prices = "-";
              }
              if (e.beijing_prices == "None") {
                e.beijing_prices = "-";
              }
            });
          }
          console.log(this.tableData, "this.tableData");
          let data = res.data.data;
          let data_keys = Object.keys(data);
          for (let i = 0; i < data_keys.length; i++) {
            let temp = data[data_keys[i]].map((item) => {
              if (
                !item ||
                item == "" ||
                item == undefined ||
                /^(-+|none)$/i.test(item)
              ) {
                return null;
              } else {
                return data_keys[i] == "current_dates"
                  ? item
                  : parseFloat(item);
              }
            });
            this[data_keys[i]] = temp.reverse();
          }
          this.setTrendOption();
        })
        .catch((error) => {
          console.log("getTrendEchartsData, error", error);
        });
    },
    setKOption() {
      this.options.dataZoom[0].startValue = this.date_list.length - 1;
      this.options.dataZoom[0].endValue = this.date_list.length - 50;
      this.options.dataZoom[1].startValue = this.date_list.length - 1;
      this.options.dataZoom[1].endValue = this.date_list.length - 50;
      //k线图
      let series_data = this.price_list.map((item) => {
        return item.map((Item) =>
          /-/.test(Item) || !Item ? "-" : toFixed(parseFloat(Item), 2)
        );
      });
      // console.log('series_data', series_data);
      this.options.series[0].data = series_data;

      let series_data_amount = this.amount_list.map((item) => {
        return /-/.test(item) || !item ? "-" : toFixed(item.replace(",", ""));
      });
      this.options.series[1].data = series_data_amount;
      let city_name =
        this.citys[this.citys.findIndex((item) => item.value == this.active)]
          .name;
      // this.options.series[0].name = city_name;
      // this.options.series[0].name = city_name;
      // this.options.legend.data = [city_name];
      this.options.xAxis[0].data = this.date_list;
      this.options.xAxis[1].data = this.date_list;
      // console.log('ref', this.instance);
      this.instance.setOption(this.options);
      this.instance.hideLoading();

      //组合图
    },
    setTrendOption() {
      this.options.xAxis[0].data = this.current_dates;
      for (let i = 0; i < this.citys.length; i++) {
        this.options.series[i].data = this[this.citys[i].value + "_prices"];
        // console.log('setTrendOption', this.citys[i].value ,this.options.series[i].data);
      }
      console.log("setTrendOption", this.options);
      this.instance.hideLoading();
      this.instance.setOption(this.options);
    },
    handleSelectCity(active) {
      if (this.activeSwitch != "k") return;
      this.active = active;
      this.initKOption();
      this.getKEchartsData();
    },
    handleSwitch(activeSwitch) {
      if (this.activeSwitch == activeSwitch) return;
      this.activeSwitch = activeSwitch;
      this.instance && this.instance.dispose();
      this.initInstance();
      if (this.activeSwitch == "k") {
        this.tableShow = false;
        this.initKOption();
        this.getKEchartsData();
        // console.log(this.form,'form');
      } else {
        this.tableShow = true;
        this.initTrendOption();
        this.getTrendEchartsData();
      }
    },
    handleResize() {
      this.resizing && window.clearTimeout(this.resizing);
      this.resizing = setTimeout(() => {
        console.log("handleResize");
        this.instance && this.instance.resize && this.instance.resize();
        this.resizing = null;
      }, 500);
    },
  },
};
</script>
<style lang="less" scoped>
.candlestick {
  flex-grow: 1;
  width: 375px;
  margin: 20px auto 0;
  padding: 20px 10px 0;
  display: flex;
  flex-flow: column nowrap;

  & > .tabs {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    flex-shrink: 0;
    &.citys {
      // height: 19px;
    }
    & > .city,
    & > .switch {
      padding: 0 10px;
      cursor: pointer;
      font-size: 14px;
      width: 50px;

      &.active {
        color: #ff2832;
      }

      &:hover {
        color: #ff2832;
      }
    }
    & > .switch {
      padding: 10px 10px;
    }
  }

  & > .echart-wrapper {
    flex-grow: 1;
    min-height: 700px;
    max-height: 700px;
    overflow: hidden;
    // display: flex;
    // flex-flow: column nowrap;
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;
    grid-gap: 20px;

    & > .echart-ref {
      //   flex-grow: 1;
      //   height: 50%;
      & > div {
        &:first-child {
          height: 100%;
        }
      }
    }
  }

  & > .form {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-end;
    font-size: 14px;

    & > div {
      // width: 50%;
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-end;
      align-items: center;

      & > div {
        width: 130px;
      }
    }
  }
}
</style>
